<template>
  <div>
    <v-dialog persistent v-model="dialog_bulk_broker" max-width="80%">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >New Broker</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-form ref="frm">
              <v-card v-for="(row, i) in editedItem.bulk.length" v-bind:key="i">
                <v-divider class="my-3"></v-divider>
                <v-row>
                  <v-col cols="3" sm="12" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >Broker</label
                    >
                    <v-text-field
                      v-model="editedItem.bulk[i].broker_name"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-2
                      "
                      :rules="[(v) => !!v || 'Broker Name is required']"
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      placeholder="Broker Name"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="12" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >Broker Code</label
                    >
                    <v-text-field
                      v-model="editedItem.bulk[i].broker_code"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-2
                      "
                      :rules="[(v) => !!v || 'Broker Code is required']"
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      placeholder="Broker Code"
                      persistent-hint
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3" sm="12" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1">
                      Auction Center</label
                    >
                    <v-select
                      v-model="editedItem.bulk[i].auction_center"
                      :items="auctionCenters"
                      return-object
                      item-text="auction_center_name"
                      item-value="id"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        select-style
                        mt-3
                      "
                      outlined
                      chips
                      multiple
                      single-line
                      height="46"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          label
                          color="bg-default"
                          class="py-1 px-2 my-0"
                          v-if="index === 0"
                        >
                          <span class="text-white text-caption ls-0">{{
                            item.auction_center_name
                          }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ editedItem.bulk[i].auction_center.length - 1 }}
                          others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="12"
                    md="3"
                    class="pa-1"
                    align-self="center"
                  >
                    <span class="pa-0" align-self="center">
                      <v-btn
                        class="mx-1 input-style"
                        outlined
                        small
                        color="red"
                        @click="remove(i)"
                        v-show="i || (!i && editedItem.bulk.length > 1)"
                        >Remove
                      </v-btn>
                    </span>
                    <span class="pa-0" align-self="center">
                      <v-btn
                        class="mx-1"
                        outlined
                        small
                        color="green"
                        @click="add()"
                        v-show="i == editedItem.bulk.length - 1"
                        >Add
                      </v-btn>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="cancel"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Cancel</v-btn
          >

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            dark
            class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import api from "../api";

export default {
  props: {
    input_data: Array,
    dialog_bulk_broker: Boolean,
  },
  name: "BrokerBulk",
  data() {
    return {
      editedItem: {
        bulk: [
          {
            broker_name: "",
            broker_code: "",
            auction_center: [],
          },
        ],
      },
      defaultItem: {
        bulk: [
          {
            broker_name: "",
            broker_code: "",
            auction_center: [],
          },
        ],
      },

      auctionCenters: [],
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.auctionCenters = await api.getAuctionCenter();
      if (
        this.input_data &&
        this.input_data?.length > 0 &&
        this.input_data != undefined &&
        this.input_data != null
      ) {
        this.editedItem.bulk = this.input_data;
        console.log("THIS", this.editedItem.bulk);
      }
    },

    validate() {
      return this.$refs.frm.validate();
    },
    reset() {
      return this.$refs.frm.reset();
    },
    cancel() {
      this.$emit("cancel_upload");
    },
    add() {
      this.editedItem.bulk.push({
        broker_name: "",
        broker_code: "",
        broker_quality: 0,
        district: null,
        certificate: null,
        auction_center: [],
        brokerType: this.brokerType,
      });
    },

    remove(index) {
      this.editedItem.bulk.splice(index, 1);
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;

        this.$emit("close_dialog_bulk_broker");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        try {
          let type = "Saved";

          await api.createBulk(this.editedItem);
          this.showSuccessAlert(`Brokers ${type}.`);
          this.close();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
