<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Catalogue
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-card class="card-shadow border-radius-xl">
                  <v-form ref="frm">
                    <v-card-text class="card-padding">
                      <v-row>
                        <v-col cols="6">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Auction center</label
                          >

                          <v-select
                            :items="auctionCenter"
                            item-text="auction_center_name"
                            item-value="id"
                            hide-details="auto"
                            v-model="editedItem.auction_center"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-3
                            "
                            outlined
                            height="46"
                            :rules="[
                              (v) => !!v || 'Auction center is required',
                            ]"
                            placeholder="Select auction center"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="c_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0"
                                  >{{ item.auction_center_code }}-{{
                                    item.auction_center_name
                                  }}</span
                                >
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="6">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span>
                            File</label
                          >

                          <v-file-input
                            prepend-inner-icon
                            v-model="editedItem.file"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-3
                            "
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            placeholder="Upload Catalogue"
                            :rules="[(v) => !!v || 'Catalogue is required']"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="card-padding d-flex justify-end">
                      <v-btn
                        @click="close"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-secondary
                          bg-light
                          py-3
                          px-6
                        "
                        >Cancel</v-btn
                      >

                      <v-btn
                        @click="save"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          py-3
                          px-6
                        "
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <GradeBulk
      v-if="dialog_bulk_grade"
      :dialog_bulk_grade="dialog_bulk_grade"
      :input_data="missing_grade"
      @close_dialog_bulk_grade="closeDialogBulkGrade()"
      @cancel_upload="cancelUpload"
    />
    <MarkBulk
      v-if="!dialog_bulk_grade && dialog_bulk_mark"
      :dialog_bulk_mark="dialog_bulk_mark"
      :input_data="missing_mark"
      @close_dialog_bulk_mark="closeDialogBulkMark()"
      @cancel_upload="cancelUpload"
    />
    <CategoryBulk
      v-if="!dialog_bulk_grade && !dialog_bulk_mark && dialog_bulk_category"
      :dialog_bulk_category="dialog_bulk_category"
      :input_data="missing_category"
      @close_dialog_bulk_category="closeDialogBulkCategory()"
      @cancel_upload="cancelUpload"
    />
    <WareHouseBulk
      v-if="
        !dialog_bulk_grade &&
        !dialog_bulk_mark &&
        !dialog_bulk_category &&
        dialog_bulk_warehouse
      "
      :dialog_bulk_warehouse="dialog_bulk_warehouse"
      :input_data="missing_warehouse"
      @close_dialog_bulk_warehouse="closeDialogBulkWarehouse()"
      @cancel_upload="cancelUpload"
    />
    <BrokerBulk
      v-if="
        !dialog_bulk_grade &&
        !dialog_bulk_mark &&
        !dialog_bulk_category &&
        !dialog_bulk_warehouse &&
        dialog_bulk_broker
      "
      :dialog_bulk_broker="dialog_bulk_broker"
      :input_data="missing_broker"
      @close_dialog_bulk_broker="closeDialogBulkBroker()"
      @cancel_upload="cancelUpload"
    />
  </div>
</template>

<script>
// import axios from "axios";
import api from "../api";
import GradeBulk from "../../../master/Grade/views/GradeBulk.vue";
import MarkBulk from "../../../master/Mark/views/MarkBulk.vue";
import CategoryBulk from "../../../master/category/views/CategoryBulk.vue";
import WareHouseBulk from "../../../master/WareHouse/views/WareHouseBulk.vue";
import BrokerBulk from "../../../master/Broker/views/BrokerBulk.vue";

export default {
  components: {
    GradeBulk,
    MarkBulk,
    CategoryBulk,
    WareHouseBulk,
    BrokerBulk,
  },
  name: "Catalogue",
  data() {
    return {
      missing_grade: [],
      dialog_bulk_grade: false,
      missing_mark: [],
      dialog_bulk_mark: false,
      missing_category: [],
      dialog_bulk_category: false,
      missing_warehouse: [],
      dialog_bulk_warehouse: false,
      missing_broker: [],
      dialog_bulk_broker: false,
      page: 1,
      pageCount: 0,
      auctionCenter: [],
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      overlay: false,
      items: [],
      search: "",
      editedIndex: -1,
      file_name: "",
      center: 0,
      editedItem: {
        file: null,
        file_name: "",
        auction_center: 0,
        missing_data: [],
      },
      defaultItem: {
        file: null,
        file_name: "",
        auction_center: 0,
      },
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    cancelUpload() {
      this.dialog_bulk_grade = false;
      this.dialog_bulk_mark = false;
      this.dialog_bulk_category = false;
      this.dialog_bulk_warehouse = false;
      this.initialize();
    },
    validate() {
      return this.$refs.frm.validate();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    async initialize() {
      try {
        this.overlay = true;
        this.auctionCenter = await api.getAuctionCenter();
        this.overlay = false;
      } catch (err) {
        this.showErrorAlert(err);
      }
    },
    async closeDialogBulkGrade() {
      this.dialog_bulk_grade = false;
      this.missing_grade = [];
      if (
        this.missing_grade.length == 0 &&
        this.missing_mark.length == 0 &&
        this.missing_category.length == 0 &&
        this.missing_warehouse.length == 0 &&
        this.missing_broker.length == 0
      ) {
        await this.resend();
      }
      this.close();
    },
    async closeDialogBulkMark() {
      this.dialog_bulk_mark = false;
      this.missing_mark = [];
      if (
        this.missing_grade.length == 0 &&
        this.missing_mark.length == 0 &&
        this.missing_category.length == 0 &&
        this.missing_warehouse.length == 0 &&
        this.missing_broker.length == 0
      ) {
        await this.resend();
      }
      this.close();
    },
    async closeDialogBulkCategory() {
      this.dialog_bulk_category = false;
      this.missing_category = [];
      if (
        this.missing_grade.length == 0 &&
        this.missing_mark.length == 0 &&
        this.missing_category.length == 0 &&
        this.missing_warehouse.length == 0 &&
        this.missing_broker.length == 0
      ) {
        await this.resend();
      }
      this.close();
    },
    async closeDialogBulkWarehouse() {
      this.dialog_bulk_warehouse = false;
      this.missing_warehouse = [];
      if (
        this.missing_grade.length == 0 &&
        this.missing_mark.length == 0 &&
        this.missing_category.length == 0 &&
        this.missing_warehouse.length == 0 &&
        this.missing_broker.length == 0
      ) {
        await this.resend();
      }
      this.close();
    },
    async closeDialogBulkBroker() {
      this.dialog_bulk_broker = false;
      this.missing_broker = [];
      if (
        this.missing_grade.length == 0 &&
        this.missing_mark.length == 0 &&
        this.missing_category.length == 0 &&
        this.missing_warehouse == 0 &&
        this.missing_broker
      ) {
        await this.resend();
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      console.log(item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);
        this.showSuccessAlert(`Catalogue Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    close() {
      this.dialog = false;
      this.overlay = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.frm.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        let filename = this.editedItem.file.name;

        let ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        if (ext == "xlsx") {
          try {
            this.overlay = true;
            let bodyFormData = new FormData();
            bodyFormData.append(
              "file",
              this.editedItem.file,
              this.editedItem.file.name
            );
            bodyFormData.append(
              "auction_center",
              this.editedItem.auction_center
            );
            let res = await api.create(bodyFormData);
            this.overlay = false;
            if (res.status === 201) {
              this.close();
              this.showSuccessAlert(`Catalogue Successfully uploaded`);
            } else {
              this.missing_grade = [];
              this.missing_mark = [];
              this.missing_category = [];
              this.missing_warehouse = [];
              this.missing_broker = [];
              this.file_name = res.file_name;
              this.center = this.editedItem.auction_center;
              this.editedItem.missing_data = res.missing_data;
              this.showWarningAlert(
                "Some data are missing. Please add them first to update catalogue"
              );
              if (res.missing_data.grade?.length > 0) {
                res.missing_data.grade.map((item) => {
                  this.missing_grade.push({
                    grade_name: item.name,
                    // grade_code: item.name.toUpperCase().substring(0, 4),
                    grade_code: item.name.toUpperCase(),
                  });
                });
                this.dialog_bulk_grade = true;
              }
              if (res.missing_data.mark?.length > 0) {
                res.missing_data.mark.map((item) => {
                  this.missing_mark.push({
                    mark_name: item.name,
                    mark_code: item.name.toUpperCase().substring(0, 4),
                    // mark_code: item.name.toUpperCase(),
                  });
                });
                this.dialog_bulk_mark = true;
              }
              if (res.missing_data.category?.length > 0) {
                res.missing_data.category.map((item) => {
                  this.missing_category.push({
                    category_name: item.name,
                  });
                });
                this.dialog_bulk_category = true;
              }
              if (res.missing_data.wareHouse?.length > 0) {
                res.missing_data.wareHouse.map((item) => {
                  this.missing_warehouse.push({
                    warehouse_name: item.name,
                    warehouse_code: item.name.toUpperCase().substring(0, 6),
                    // warehouse_code: item.name.toUpperCase(),
                  });
                });
                this.dialog_bulk_warehouse = true;
              }
              if (res.missing_data.broker?.length > 0) {
                res.missing_data.broker.map((item) => {
                  this.missing_broker.push({
                    broker_code: item.name,
                    auction_center: item.auction_center,
                  });
                });
                this.dialog_bulk_broker = true;
              }
            }
          } catch (err) {
            this.showErrorAlert(err.message);
            this.close();
          }
        } else {
          this.showWarningAlert("Please use .xlsx file ");
        }
      }
    },
    async resend() {
      try {
        console.log("RESENDING");
        this.overlay = true;
        let res = await api.resend({
          file_name: this.file_name,
          auction_center: this.center,
        });
        if (res.status === 201) {
          this.close();
          this.showSuccessAlert(`Catalogue Successfully uploaded`);
        } else {
          this.editedItem.file_name = res.file_name;
          this.editedItem.missing_data = res.missing_data;
          this.showWarningAlert(
            "Some data are missing. Please add them first to update catalogue"
          );
          if (res.missing_data.grade?.length > 0) {
            res.missing_data.grade.map((item) => {
              this.missing_grade.push({
                grade_name: item.name,
                grade_code: item.name,
              });
            });
            this.dialog_bulk_grade = true;
          }
          if (res.missing_data.mark?.length > 0) {
            res.missing_data.mark.map((item) => {
              this.missing_mark.push({
                mark_name: item.name,
                mark_code: item.name,
              });
            });
            this.dialog_bulk_mark = true;
          }
          if (res.missing_data.category?.length > 0) {
            res.missing_data.category.map((item) => {
              this.missing_category.push({
                category_name: item.name,
              });
            });
            this.dialog_bulk_category = true;
          }
        }
      } catch (err) {
        this.showErrorAlert(err.message);
      } finally {
        this.overlay = false;
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Catalogue" : "Edit Catalogue";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
